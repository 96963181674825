<template>
  <div>
    <div v-if="actions.length > 0" class="d-flex flex-end mb20">
      <el-button 
        v-for="item in actions"
        type="primary"
        :key="item.label"
        @click="clickHandler(item)"
      >
        {{item.label}}
      </el-button>
    </div>
    <TitleCard title="User Profile">
      <InfoItems :info-items="infoItems" v-bind="$attrs" />
    </TitleCard>
    <div v-if="hasHistory" class="mt20">
      <TitleCard title="Change History">
        <CommonTable :table-items="historyTableItems" />
      </TitleCard>
    </div>
  </div>
</template>
<script>
import InfoItems from '@/components/InfoItems/index.vue'
import TitleCard from '@/components/TitleCard/index.vue'
import CommonTable from '@/components/CommonTable/index.vue'
export default {
  components: {
    InfoItems,
    TitleCard,
    CommonTable
  },
  props: {
    infoItems: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    hasHistory: {
      type: Boolean,
      default: true
    },
    cid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      historyTableItems: [
        {
          prop: 'id',
          label: 'ID',
          classname: 'text-blue'
        },
        {
          prop: 'change_history',
          label: 'Change History',
          classname: ''
        },
        {
          prop: 'time',
          label: 'Creation Time',
          classname: ''
        },
        {
          prop: 'operator',
          label: 'Operator',
          classname: '',
        }
      ]
    }
  },
  methods: {
    clickHandler (data) {
      if (data.type == 'jump') {
        this.$router.push({
          path: data.url,
          query: {id: this.cid}
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>