<template>
  <div class="common-table-wrapper">
    <div class="common-table_main">
      <el-table
        :data="tableDta"
        style="width: 100%"
        fit
        ref="commonTable"
      >
        <el-table-column
          v-if="hasSelection"
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableItems"
          :key="item.prop + index"
          :label="item.label"
          :width="item.width"
          :prop="item.prop"
        >
          <template slot-scope="scope">
            <div v-if="item.isSwitch" >
              <el-switch
                :disabled="!hasSwitchPermit"
                v-model="scope.row[item.prop]"
                @change="changeStatusHandler(scope.row, item.methods)"
              />
            </div>
            <div v-else-if="item.list">
              <div class="d-flex" v-for="(l, l_i) in item.list" :key="l_i">
                <span>{{ l.label }}</span>
                <span>{{ computedVal(l, scope.row) }}</span>
              </div>
            </div>
            <div v-else :class="item.classname" @click="goHandler(scope.row, item)">
              {{ computedVal(item, scope.row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="actions.length > 0"
          label="Actions"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-for="item in actions" :key="item.label" @click="clickHandler(scope.row, item)">
              <el-button type="primary" class="mb5">{{ item.label }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="hasPagination" class="pagination-wrapper d-flex mt20">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        @size-change="sizeChangeHandler"
        @current-change="currentChangeHandler"
        :page-sizes="[20, 40, 60, 80, 100]"
        :current-page.sync = "params.page"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
export default {
  props: {
    getTableDataFunc: {
      type: Function,
      default () {
        return ''
      }
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    tableItems: {
      type: Array,
      default: () => []
    },
    hasPagination: {
      type: Boolean,
      default: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    tableType: {
      type: String,
      default: 'users'
    },
    pageSize: {
      type: Number,
      default: 20
    },
    switchChangeFunc: {
      type: Function,
      default () {
        return ''
      }
    },
    hasSwitchPermit: {
      type: Boolean,
      default: true
    },
    outParams: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableDta: [],
      total: 0,
      params: {
        page: 1,
        per_page: this.pageSize
      }
    }
  },
  computed: {
    computedVal () {
      return function (info, data) {
        return utils.getComputedValFromProp(info, data)
      }
    }
  },
  created () {
    this.initTableData()
  },
  methods: {
    clickHandler (data, action) {
      if (action.type == 'jump') {
        if (!action.params) {
          this.$router.push({
            path: action.url,
            query: {id: data.id}
          })
          return
        }
        const { params, url } = action
        let query = {}
        params.forEach((p) => {
          query[p.key] = data[p.prop]
        })
        this.$router.push({
          path: url,
          query
        })
        return
      }
    },
    goHandler (row, item) {
      if (item.clickInfo) {
        this.$router.push({
          path: item.clickInfo.url,
          query: {
            id: row[item.clickInfo.key]
          }
        })
      }
    },
    reloadData () {
      this.params.page = 1
      this.initTableData()
    },
    async initTableData () {
      try {
        if (this.getTableDataFunc) {
          let params = JSON.parse(JSON.stringify(this.params))
          if (this.outParams) {
            params = Object.assign(this.outParams, params)
          }
          const res = await this.getTableDataFunc(params)
          if (res.data[this.tableType]) {
            const arr = JSON.parse(JSON.stringify(res.data[this.tableType]))
            arr.forEach((a) => {
              const keys = Object.keys(a)
              keys.forEach((k) => {
                if(utils.isPlainObject(a[k]) && !utils.isEmptyObject(a[k]) && Object.hasOwn(a[k], 'name')) {
                  a[k + '_name'] = a[k].name
                }
              })
            })
            this.tableDta = arr
            this.total = res.data.page.total_count
          }
        }
      } catch (error) {
        
      }
    },
    async changeStatusHandler (data, api) {
      try {
        if (api) {
          const res = await api(data.id, data.active)
          if (res.code >= 200 && res.code < 300) {
            this.$message.success('Update Success')
          }
        }
      } catch (error) {
        
      }
    },
    sizeChangeHandler (val) {
      this.params = {
        page: 1,
        per_page: val
      }
      this.initTableData()
    },
    currentChangeHandler (val) {
      this.params.page = val
      this.initTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
.common-table-wrapper {
  height: 100%;
  box-sizing: border-box;
}
.common-table_main {
  max-height: calc(100% - 72px);
  overflow-y: auto;
}
</style>