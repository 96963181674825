<template>
  <div>
    <InfoViewFrame :info-items="infoItems" :actions="actions" />
  </div>
</template>
<script>
import InfoViewFrame from '@/components/InfoViewFrame/index.vue'
export default {
  components: {
    InfoViewFrame
  },
  data () {
    return {
      infoItems: [
        {
          label: 'Name',
          value: 'Shang Lei'
        },
        {
          label: 'Name',
          value: 'Shang Lei'
        },
        {
          label: 'Name',
          value: 'Shang Lei'
        },
        {
          label: 'Name',
          value: 'Shang Lei'
        },
        {
          label: 'Name',
          value: 'Shang Lei'
        }
      ],
      actions: [
        {
          label: 'Edit',
          url: '/resources/institutions/update?id=1',
          type: 'jump'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>