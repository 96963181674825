<template>
  <div v-if="item" class="infoitems-content">
    <div class="infoitems-groups d-flex flex-wrap">
      <div class="infoitems-group" v-for="(info, index) in infoItems" :key="index" :style="`width:${itemWidth};`">
        <div class="infoitems-group_name">{{ info.label || info.name }}</div>
        <div class="infoitems-group_txt" :class="info.infoclass">{{ computedVal(info) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
export default {
  props: {
    infoItems: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {}
    },
    itemWidth: {
      type: String,
      default: '236px'
    }
  },
  data () {
    return {}
  },
  computed: {
    computedVal () {
      return function (info) {
        return utils.getComputedValFromProp(info, this.item)
        // if (info.prop) {
        //   const prop = info.prop
        //   if (Object.hasOwn(this.item, prop) && typeof this.item[prop] === 'boolean') {
        //     return this.item[prop] ? 'Yes' : 'No'
        //   }
        //   if (Object.hasOwn(this.item, prop) && utils.isPlainObject(this.item[prop]) && this.item[prop]) {
        //     if (info.objectKey) return this.item[prop][info.objectKey]
        //     return this.item[prop].name || '-'
        //   }
        //   if (prop == 'from_institution_los') return this.item[prop] + ' ' + 'Days'
        //   return this.item[prop] || '-'
        // }
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.infoitems-group {
  width: 236px;
  margin-bottom: 32px;
  flex: none;
  &_name {
    color: $text-color-secondarygray;
    font-size: 14px;
    margin-bottom: 3px;
    line-height: 20px;
  }
  &_txt {
    font-size: 14px;
    font-weight: 500;
    color: $text-color-darkblue;
    line-height: 20px;
  }
}
</style>