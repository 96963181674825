<template>
  <div class="titlecard">
    <div v-if="title" class="titlecard_title card_title mb20">
      {{title}}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.titlecard {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: $box-shadow;
  padding: 12px 70px 30px 30px;
  box-sizing: border-box;
}
</style>